<template>
  <div class="sign_in">
    <div class="return_icon">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
    </div>
    <div class="sign_in_main">
      <div class="sign_in_title1">您好！</div>
      <div class="sign_in_title2">欢迎您参加面试</div>
      <div class="sign_in_title3">您的面试时间为</div>
      <div class="sign_in_title4">2023年12月23日 12:34 - 13:34</div>
      <div class="sign_box">
        <div class="sign_btn" @click="$router.go(-1)">签到</div>
        <div class="sign_btn_btm">
          <i class="el-icon-location-outline"></i>
          您已进入可签到区域
        </div>
      </div>
    </div>

    <div>
      <baidu-map @ready="getCurrentCity" :center="center" :zoom="zoom">
        <p>
          当前城市：
          {{ result_address.province }}
          {{ result_address.city }}
          {{ result_address.district }}
          {{ result_address.street }}
          {{ result_address.street_number }}
        </p>
        <p>经度：{{ result.longitude }}</p>
        <p>纬度：{{ result.latitude }}</p>
      </baidu-map>
百度
      <baidu-map
        class="box_map"
        v-if="map_show"
        :center="locations"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="handler"
        @click="clickLocation"
      >
        <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
        ></bm-geolocation>
      </baidu-map>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result:{},
      result_address: {},
      center: {},
      // zoom: 10

      locations: {},
      zoom: 10,
      BMap: {},
      map: {},
      map_show: false,
    };
  },
  mounted() {
    this.map_show = true;
  },
  methods: {
    getCurrentCity({ BMap }) {
      this.result = {};
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition((result) => {
        if (geolocation.getStatus() === 0) {
          console.log(result);
          this.result_address = result.address;
          this.result = result;
        } else {
          this.currentCity = "定位失败";
        }
      });
    },

    handler({ BMap, map }) {
      // 保存百度地图类
      this.BMap = BMap;
      this.map = map;
      this.center = "";
      this.zoom = 15;
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition((res) => {
        // IP地址赋值给locations对象
        console.log(res);
        this.locations.lng = res.point.lng;
        this.locations.lat = res.point.lat;
      });
    },


    clickLocation(e) {
      console.log(e);
      this.locations.longitude = e.point.lng;
      this.locations.latitude = e.point.lat;
      const BMapGL = this.BMap;
      const map = this.map;
      map.clearOverlays();
      const marker = new BMapGL.Marker(
        new BMapGL.Point(e.point.lng, e.point.lat)
      );
      map.addOverlay(marker);
      const geoc = new BMapGL.Geocoder();
      geoc.getLocation(e.point, (rs) => {
        const addressComp = rs.addressComponents;
        console.log(addressComp);
      });
    },
  },
};
</script>

<style scoped>
.sign_in {
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: #fff;
}
.return_icon {
  height: 2.7rem;
  line-height: 2.7rem;
}
.sign_in_main {
  box-sizing: border-box;
  padding: 0 2rem;
}
.sign_in_title1 {
  color: #5b5b5b;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2.5rem;
}
.sign_in_title2 {
  color: #a9a9a9;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.sign_in_title3 {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 5rem;
}
.sign_in_title4 {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.sign_box {
  margin-top: 5rem;
  text-align: center;
}
.sign_btn {
  display: inline-block;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 6.25rem;
  color: #fff;
  font-size: 1.5rem;
  background: linear-gradient(313deg, #18aef3 -66.5%, #0a76e2 83.19%);
}
.sign_btn_btm {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 1.25rem;
}
.box_map {
  width: 100%;
  height: 300px;
  background: pink;
}
</style>
